// This is used to expose PHAF global declarations.
import {Auth} from '@verily-src/auth';
import {Nav} from '@verily-src/nav';
import '@verily-src/phaf-runtime-helpers';
import {ApplicationSuite} from '@verily-src/phaf-runtime-helpers';
import {UnifiedApi} from '@verily-src/phaf-unified-api';

class EnrollmentAppSuiteUtility implements UnifiedApi {
  get nav() {
    return Nav;
  }

  get auth(): typeof Auth {
    return Auth;
  }

  /** Returns the application suite that is currently running, see go/phaf-app-api. */
  getApplicationSuite(): ApplicationSuite {
    return ApplicationSuite.ENROLLMENT;
  }

  // This is a placeholder implementation since it's not used by Enrollment running
  // as its own single-spa outside Verily Me Web
  async hasAccess(_navPath: string): Promise<boolean> {
    return false;
  }
}

// Anything exported from this file is importable by other in-browser modules.
const api = new EnrollmentAppSuiteUtility();

export {api};
